import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AboutChairman from "../components/AboutChairman";
import Topbar from "../components/Topbar";
// import ChairmanSlogan from "../components/ChairmanSlogan"

const AboutChairmanPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Chairman Message">
            <Topbar />
            <NavOne />
            <PageHeader title="Chairman Message" />
            <AboutChairman />
            <Footer />
        </Layout>
    );
};

export default AboutChairmanPage;
