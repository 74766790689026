import React from "react";
import chairman from "../assets/images/chairman.jpg";

const AboutChairman = () => {
  return (
    <section className="team-details">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="team-details__content">
              <h2 className="team-details__title title">Chairman's Message</h2>
              <p className="team-details__text">
                Shanti Asiatic Schools have over the last decade carved a niche for itself in imparting of quality
                education to multitudes of young boys and girls, combining academic excellence with efforts in
                areas such as sports, arts, music and culture initiated in order to enrich a holistic development.
                A strong school education system is foundational for our nation's socio-economic development
                and global competitiveness.<br />
                In keeping with rapid changes in all spheres of life with the overwhelming advent of technology and new innovative methodologies, our School has focused on installing new systems and creating high-quality inputs such as modern teaching methods and tools that will help students to hone basic skills like reading and mathematics and also develop in all round content.<br />
                My greetings and felicitations to the Principal, Teachers, Staff and the pride of the School, our Students and their Parents - for the contributions in making our School what it is and for their great endeavors in times to come.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={chairman} alt="Chairman" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/#">Brijmohan Chiripal</a>
                </h2>
                <p className="team-one__designation">Chairman</p>
                <p className="team-one__designation">Shanti Asiatic Schools</p>
              </div>
              {/* <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutChairman;
